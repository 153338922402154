<template>
  <div class="home">
    <v-container class="text-center">
      <h1 class="mb-3">
        <ChangingText :setValues="text"></ChangingText>
      </h1>
      <p>
        Hier bei <span class="ta">TrustyApps</span> greife ich, Marvin Rust,
        Ihrem Softwareenwicklungs-Team unter die Arme. Ich analysiere und
        berate, finde Programmierfehler, spüre Schwachstellen und Potenziale
        auf. So erhalten Sie klare, konstruktive Handlungsempfehlungen. Und
        jederzeit die Option, Ihre Softwareentwicklung von
        <span class="ta">TrustyApps</span> finalisieren zu lassen.<br />
        Überprüfen Sie unsere aktuellen Projekte oder werfen Sie einen Blick auf
        unsere Blog-Einträge, so oder so sind Sie hier genau richtig.
      </p>
    </v-container>

    <v-container fluid>
      <BlogCarousel></BlogCarousel>
    </v-container>

    <v-container fluid>
      <v-row class="trianglesTopRightAmber"></v-row>
      <v-row class="contentAmber" align="center" justify="center">
        <v-col
          cols="9"
          xs="9"
          sm="9"
          md="3"
          lg="3"
          xl="3"
          align="center"
          justify="center"
        >
          <v-img
            contain
            height="120"
            width="180"
            :src="require('@/assets/icon/black.svg')"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
        <v-col cols="9" xs="9" sm="9" md="9" lg="9" xl="9">
          <h1 class="ta">"Grundlegend bin ich einfach faul!"</h1>
          <p>
            Eine Aussage, die man Treffen kann von
            <strong>Marvin Rust</strong> jedoch 100% ernst gemeint ist.<br />
            Dies bedeutet nicht, dass Tasks nicht oder nur halbherzig bearbeitet
            werden, ganz im Gegenteil! Es geht darum die gestellten Aufgaben zu
            bewältigen und das mit der höchst möglichen Effizenuz und
            Motivation.<br />
            Warum dann aber 'faul'? Dies ist ein Grundsatz, um Aufgaben von
            Anfang an direkt mit voller Kraft anzugehen um diese nicht doppelt
            oder gar dreifach erledigen zu müssen. Jede Applikation und jedes
            Projekt verdient diese Herangehensweise! Dies ist auch der Spirit
            hinter
            <span class="ta">TrustyApps</span>.
          </p>
        </v-col>
      </v-row>
      <v-row class="trianglesBottomRightAmber"></v-row>
    </v-container>

    <v-container>
      <v-row justify="center">
        <v-col cols="12" align="center" justify="center">
          <p style="font-size: 42px; font-weight: 400">
            Die Einstellungen meines <span class="ta">BIOS</span>
          </p>
          <v-divider></v-divider>
          <p>
            Bewährte Prozesse gibt es seitdem die Zeit voranschreitet. Viele von
            Ihnen wurden unter bestimmten Gesichtspunkten optimiert und
            weiterentwickelt. Häufig stellt sich jedoch heraus, dass die
            Gesichtspunkte unter denen jener Prozess weiterentwickelt wurde
            nicht die passenden waren. Hier empfiehlt sich
            <strong>quer, anders und neu </strong> zu denken. <br />
            Marvin überprüft Prozesse aus einem anderen Blickwinkel und steht
            bereit Ihnen den Spiegel vor zu halten, um die optimalste Lösung zu
            kreieren. <br />
            Welche Punkte spielen hier eine Rolle? Auf jeden Fall diese vier:
          </p>
        </v-col>
        <IconCol
          icon="mdi-lightbulb-outline"
          title="Ideen umsetzen"
          description="Eine Idee ist enstanden? Lass sie nciht liegen, geh Sie einfach an! Frei nach dem Gedanken: ''Geht nicht gibt es nicht!''"
        ></IconCol>
        <IconCol
          icon="mdi-shield-star-outline"
          title="Potenzial ausschöpfen"
          description="Schraube Deine Netzwerk-geschwindigkeit nach oben und fahre mit Top-Speed in die Zukunft."
        ></IconCol>
        <IconCol
          icon="mdi-spider-web"
          title="Vernetzen & Diskutieren"
          description="Deine Eindrücke können nur aus dem Umfeld kommen, welche Du kennst. Vernetze Dich mit anderen um neue Eindrücke und Ideen zu gewinnen."
        ></IconCol>
        <IconCol
          icon="mdi-star-outline"
          title="Star-Modus"
          description="Bitte und erfrage Rückmeldungen und Berwertungen. Wie soll man wachsen ohne Rückmeldungen worin man wachsen muss?"
        ></IconCol>
      </v-row>
    </v-container>

    <v-container fluid>
      <v-row class="trianglesTopLeftGrey"></v-row>
      <v-row class="contentGrey" align="center" justify="center">
        <v-col cols="11" align="center" justify="center">
          <h2>Bereits mehr als</h2>
        </v-col>
        <BigNumberCol
          :amount="amountOfProjects"
          description="Abgeschlossene Projekte"
        ></BigNumberCol>
        <BigNumberCol
          :amount="amountOfBlogEntries"
          description="Blog Posts"
        ></BigNumberCol>
        <BigNumberCol
          :amount="amountOfComments"
          description="Abgegebene Kommentare"
        ></BigNumberCol>
        <BigNumberCol
          :amount="amountOfActivYears"
          description="Aktive Jahre"
        ></BigNumberCol>
      </v-row>
      <v-row class="trianglesBottomRightGrey"></v-row>
    </v-container>

    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12" align="center" justify="center">
          <p style="font-size: 42px; font-weight: 400">Mein Tech Stack</p>
        </v-col>
        <v-col cols="12" align="center" justify="center">
          <p>
            JavaScript, HTML und CSS, Node.js und noch viel mehr: Ich spreche
            Ihre Programmiersprache. Dazu nutzen ich genau die Technologien, die
            Ihnen nützen. Unter anderem verwende ich:
          </p>
        </v-col>

        <RoundImageCard picturename="Vue.js.png"></RoundImageCard>
        <RoundImageCard picturename="Node.js.png"></RoundImageCard>
        <RoundImageCard picturename="js.png"></RoundImageCard>
        <RoundImageCard picturename="php.png"></RoundImageCard>
        <RoundImageCard picturename="html.png"></RoundImageCard>
        <RoundImageCard picturename="css.png"></RoundImageCard>
        <RoundImageCard picturename="android.png"></RoundImageCard>
        <RoundImageCard picturename="c-sharp.png"></RoundImageCard>
        <RoundImageCard picturename="c++.png"></RoundImageCard>
      </v-row>
    </v-container>

    <v-container fluid>
      <v-row class="trianglesTopRightGrey"></v-row>
      <v-row class="contentGrey2" align="center" justify="center">
        <v-col
          cols="12"
          xs="6"
          sm="6"
          md="6"
          lg="6"
          xl="6"
          align="center"
          justify="center"
        >
          <v-img contain width="350" :src="require('@/assets/rust_full.jpg')">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
        <v-col cols="12" xs="5" sm="5" md="5" lg="5" xl="5" class="ma-3">
          <p style="font-size: 32px; font-weight: 400">
            Kennenlernen ist der beste Wissens-transfer
          </p>
          <p style="font-size: 18px; font-weight: 400" class="ma-3">
            Gemeinsam finden wir auf alle IT-Fragen eine Antwort.<br />Probieren
            Sie es aus: Jetzt anfragen!<br />
            <br />
            E-Mail:<span style="float: right">
              <v-btn
                @click="
                  openLink(
                    'mailto:contact@trustyapps.de&subject=TrustyApps - Request - YOUR SUBJECT'
                  )
                "
                >contact@trustyapps.de</v-btn
              >
            </span>
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BlogCarousel from "@/components/ShowElements/BlogCarousel.vue";
import BigNumberCol from "@/components/BaseElements/BigNumberCol.vue";
import IconCol from "@/components/BaseElements/IconCol.vue";
import ChangingText from "@/components/BaseElements/ChangingText.vue";
import RoundImageCard from "@/components/BaseElements/RoundImageCard.vue";

export default {
  data: () => ({
    amountOfProjects: 0,
    amountOfBlogEntries: 0,
    amountOfComments: 0,
    text: [
      "Herzlich Willkommen!",
      "Sie haben Es geschafft",
      "und sind auf der Website",
      "von TrustyApps gelandet.",
      "Hier erfahren Sie mehr",
      "rund um spannende",
      "IT-Themen und die",
      "aktuellen Projekte",
      "von TrustyApps.",
    ],
  }),

  name: "Home",
  components: {
    BlogCarousel,
    BigNumberCol,
    IconCol,
    ChangingText,
    RoundImageCard,
  },

  mounted() {
    this.amountOf();
  },

  methods: {
    openLink(link) {
      location.href = link;
    },

    amountOf() {
      this.$store.dispatch("getAmountOfBlogposts").then((response) => {
        if (response.msg != null) {
          this.amountOfBlogEntries = 0;
        } else {
          this.amountOfBlogEntries = response.AMOUNT;
        }
      });

      this.$store.dispatch("getAmountOfComments").then((response) => {
        if (response.msg != null) {
          this.amountOfComments = 0;
        } else {
          this.amountOfComments = response.AMOUNT;
        }
      });

      this.$store.dispatch("getAmountOfProjects").then((response) => {
        if (response.msg != null) {
          this.amountOfProjects = 0;
        } else {
          this.amountOfProjects = response.AMOUNT;
        }
      });
    },
  },

  computed: {
    amountOfActivYears() {
      return new Date().getFullYear() - new Date("2020-11-16").getFullYear();
    },
  },
};
</script>

<style>
.contentAmber {
  background: #ffca28;
  color: #fff;
}
.contentGrey {
  background: #616161;
  color: #fff;
}
.contentGrey2 {
  background: #676772;
  color: #fff;
  margin-bottom: -15px;
}

.trianglesTopLeftGrey {
  margin-top: 50px;
  height: 100px;
  background: linear-gradient(to bottom left, transparent 49.8%, #616161 50%)
      bottom / 100% 73%,
    linear-gradient(to bottom left, transparent 49.8%, #bdbdbd 50%) bottom /
      100% 84%,
    linear-gradient(to bottom left, transparent 49.8%, #e0e0e0 50%) bottom/ 100%
      95%;
  background-repeat: no-repeat;
}

.trianglesTopRightGrey {
  margin-top: 50px;
  height: 100px;
  background: linear-gradient(to bottom right, transparent 49.8%, #676772 50%)
      bottom / 100% 73%,
    linear-gradient(to bottom right, transparent 49.8%, #bdbdbd 50%) bottom /
      100% 84%,
    linear-gradient(to bottom right, transparent 49.8%, #e0e0e0 50%) bottom/
      100% 95%;
  background-repeat: no-repeat;
}

.trianglesBottomRightGrey {
  margin-bottom: 50px;
  height: 100px;
  background: linear-gradient(to top right, transparent 49.8%, #616161 50%) top /
      100% 73%,
    linear-gradient(to top right, transparent 49.8%, #bdbdbd 50%) top / 100% 84%,
    linear-gradient(to top right, transparent 49.8%, #e0e0e0 50%) top/ 100% 95%;
  background-repeat: no-repeat;
}

.trianglesTopRightAmber {
  margin-top: 50px;
  height: 100px;
  background: linear-gradient(to bottom right, transparent 49.8%, #ffca28 50%)
      bottom / 100% 73%,
    linear-gradient(to bottom right, transparent 49.8%, #ffe082 50%) bottom /
      100% 84%,
    linear-gradient(to bottom right, transparent 49.8%, #ffecb3 50%) bottom/
      100% 95%;
  background-repeat: no-repeat;
}

.trianglesBottomRightAmber {
  margin-bottom: 50px;
  height: 100px;
  background: linear-gradient(to top right, transparent 49.8%, #ffca28 50%) top /
      100% 73%,
    linear-gradient(to top right, transparent 49.8%, #ffe082 50%) top / 100% 84%,
    linear-gradient(to top right, transparent 49.8%, #ffecb3 50%) top/ 100% 95%;
  background-repeat: no-repeat;
}

.triangle-down {
  box-sizing: content-box;
  width: 50%;
  height: 0;
  padding-left: 50%;
  padding-top: 5%;
  overflow: hidden;
}
.triangle-down div {
  width: 0;
  height: 0;
  margin-left: -1000px;
  margin-top: -100px;
  border-left: 1000px solid transparent;
  border-right: 1000px solid transparent;
  border-top: 100px solid #ffe082;
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 30px 100vw;
  border-color: transparent transparent #007bff transparent;
}

.triangle2 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 100px 100vw;
  border-color: transparent transparent #ffe082 transparent;
}

.diamonds {
  margin-top: 100px;
  height: 80px;
  background-image: linear-gradient(
      to bottom right,
      transparent 50%,
      #be955a 51%
    ),
    linear-gradient(to top right, transparent 50%, #be955a 51%),
    linear-gradient(to bottom left, transparent 50%, #cca56b 51%),
    linear-gradient(to top left, transparent 50%, #cca56b 51%);
  background-position: 0 0, 0 100%, 100% 0, 100% 100%;
  background-size: 50.00001% 50%;
  background-repeat: no-repeat;
}
.rounded-card {
  border-radius: 50% !important;
}
</style>
