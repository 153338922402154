<template>
  <p v-html="value" :style="overrideStyles"></p>
</template>

<script>
export default {
  data: () => ({
    value: String,
    counter: 0,
    chars: "!<>-_\\/[]{}—=+*^?#________",
    overrideStyles: {
      "min-height": "0px",
    },
  }),

  props: {
    setValues: Array,
  },

  mounted() {
    const next = () => {
      this.setText(this.setValues[this.counter]).then(() => {
        setTimeout(next, 1600);
      });
      this.counter = (this.counter + 1) % this.setValues.length;
    };

    next();
    this.overrideStyles["min-height"] = this.height() + "px";
  },

  computed: {},

  methods: {
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 110;
        case "sm":
          return 80;
        case "md":
          return 50;
        case "lg":
          return 50;
        case "xl":
          return 50;
        default:
          return 0;
      }
    },

    setText(newText) {
      const oldText = this.value;
      const length = Math.max(oldText.length, newText.length);
      const promise = new Promise((resolve) => (this.resolve = resolve));
      this.queue = [];

      for (let i = 0; i < length; i++) {
        const from = oldText[i] || "";
        const to = newText[i] || "";
        const start = Math.floor(Math.random() * 40);
        const end = start + Math.floor(Math.random() * 40);
        this.queue.push({
          from,
          to,
          start,
          end,
        });
      }

      cancelAnimationFrame(this.frameRequest);
      this.frame = 0;
      this.update();
      return promise;
    },
    update() {
      let output = "";
      let complete = 0;

      for (let i = 0, n = this.queue.length; i < n; i++) {
        let { from, to, start, end, char } = this.queue[i];

        if (this.frame >= end) {
          complete++;
          output += to;
        } else if (this.frame >= start) {
          if (!char || Math.random() < 0.28) {
            char = this.randomChar();
            this.queue[i].char = char;
          }

          output += `<span class="dud">${char}</span>`;
        } else {
          output += from;
        }
      }

      this.value = output;

      if (complete === this.queue.length) {
        this.resolve();
      } else {
        this.frameRequest = requestAnimationFrame(this.update);
        this.frame++;
      }
    },
    randomChar() {
      return this.chars[Math.floor(Math.random() * this.chars.length)];
    },
  },
};
</script>

<style>
</style>