<template>
  <div class="about">
    <v-row justify="center">
      <v-col xl="4" lg="5" md="5" sm="11" xs="11" cols="11">
        <v-card elevation="5" shaped>
          <v-card-title>Marvin Rust</v-card-title>
          <v-card-text>
            <p class="font-weight-bold">Bachelor of Engineering</p>
            <p>
              Information- and Electrical Engineering<br />
              Hochschule für Angewandte Wissenschaften Hamburg (HAW Hamburg)
            </p>
          </v-card-text>
          <v-card-text>
            <p class="font-weight-bold">Automatisierungstechnik/Techniker</p>
            <p>
              Electronics technicians for automation technology<br />
              Industrie- und Handelskammer (Chambers of Commerce & Industry)
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col xl="4" lg="5" md="5" sm="11" xs="11" cols="11">
        <v-card elevation="5" shaped>
          <v-card-text>
            <div class="font-weight-bold ml-8 mb-2">Today</div>

            <v-timeline align-top dense>
              <v-timeline-item
                v-for="message in messages"
                :key="message.time"
                :color="message.color"
                small
              >
                <div>
                  <div class="font-weight-normal">
                    <strong>{{ message.from }}</strong> @{{ message.time }}
                  </div>
                  <div>{{ message.message }}</div>
                </div>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col xl="3" lg="10" md="10" sm="11" xs="11" cols="11">
        <v-row>
          <v-col xl="6" lg="6" md="6" sm="6" xs="6" cols="12">
            <v-avatar height="250" width="250">
              <v-img height="250" :src="require('@/assets/rust.jpg')">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" xs="6" cols="12">
            <p class="font-weight-medium">Marvin Rust</p>
            <p class="font-weight-thin">Engineer & Software Developer</p>
            <v-tooltip
              v-for="reference in externalReferences"
              :key="reference.link"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="openLink(reference.link)"
                  class="mx-2"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="24px">{{ reference.icon }}</v-icon>
                </v-btn>
              </template>
              <span>{{ reference.name }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>



<script>
export default {
  data: () => ({
    messages: [
      {
        from: "Rust, Marvin",
        message: "Comments enabled",
        time: "2021-08-01",
        color: "black",
      },
      {
        from: "Rust, Marvin",
        message: "Working on gradients and styling",
        time: "2020-11-24",
        color: "primary lighten-1",
      },
      {
        from: "Rust, Marvin",
        message: "About page under construction.",
        time: "2020-11-09",
        color: "black",
      },
      {
        from: "Rust, Marvin",
        message: "Navbar & Footer created.",
        time: "2020-11-08",
        color: "primary lighten-1",
      },
    ],
    externalReferences: [
      {
        name: "Mail",
        icon: "mdi-mail",
        link: "mailto:contact@trustyapps.de?subject=TrustyApps - Request - YOUR SUBJECT",
      },
      {
        name: "LinkedIn",
        icon: "mdi-linkedin",
        link: "https://www.linkedin.com/company/trustyapps/",
      },
      {
        name: "Google Play Store",
        icon: "mdi-google",
        link: "https://play.google.com/store/apps/dev?id=6301626033358629602&hl",
      },
      {
        name: "Youtube",
        icon: "mdi-youtube",
        link: "https://www.youtube.com/channel/UCvdR2pbutl4EU6LOLUM3AZg",
      },
      {
        name: "Twitch",
        icon: "mdi-twitch",
        link: "https://www.twitch.tv/m4rvpheus",
      },
      {
        name: "Website",
        icon: "mdi-web",
        link: "https://applications.trustyapps.de",
      },
    ],
  }),
  methods: {
    openLink: function (link) {
      window.open(link, "_parent ");
    },
  },
};
</script>