<template>
  <v-col
    cols="5"
    xs="5"
    sm="5"
    md="5"
    lg="3"
    xl="3"
    align="center"
    justify="center"
  >
    <p class="bigNumber">{{ amount }}</p>
    <p>{{ description }}</p>
  </v-col>
</template>

<script>
export default {
  props: {
    amount: Number,
    description: String,
  },
};
</script>

<style>
.bigNumber {
  font-size: 6em;
  font-weight: 400;
}
</style>