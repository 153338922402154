<template>
  <v-card
    :style="overrideStyles"
    class="rounded-card ma-2"
    align="center"
    justify="center"
  >
    <v-avatar :size="cardSize">
      <v-img
        contain
        :max-width="imgSize"
        :src="getImgUrl(picturename)"
        :alt="picturename"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-avatar>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    cardSize: 0,
    imgSize: 0,
    breathingTime: 0,
    overrideStyles: {
      "-webkit-animation": "",
      animation: "",
      "-webkit-font-smoothing": "antialiased",
    },
  }),

  props: {
    picturename: String,
  },

  mounted() {
    this.cardSize = this.getRandomSize(200, 80);
    this.imgSize = this.cardSize - 40;
    this.breathingTime = this.getRandomSize(5, 3);
    this.overrideStyles["-webkit-animation"] =
      "breathing " + this.breathingTime + "s ease-out infinite normal";
    this.overrideStyles.animation =
      "breathing " + this.breathingTime + "s ease-out infinite normal";
  },

  methods: {
    getImgUrl(pictureName) {
      if (typeof pictureName == "undefined") {
        return;
      }
      return require("@/assets/languages/" + pictureName);
    },
    getRandomSize(min, max) {
      return Math.random() * (max - min) + min;
    },
  },
};
</script>

<style>
@-webkit-keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}
</style>