<template>
  <v-col
    cols="5"
    xs="5"
    sm="5"
    md="5"
    lg="3"
    xl="3"
    align="center"
    justify="center"
  >
    <v-btn fab rounded text x-large color="primary">
      <v-icon>{{ icon }}</v-icon>
    </v-btn>
    <p>
      <strong>{{ title }}</strong>
    </p>
    <p>{{ description }}</p>
  </v-col>
</template>

<script>
export default {
  props: {
    icon: String,
    title: String,
    description: String,
  },
};
</script>

<style>
</style>