<template>
  <v-carousel
    light
    cycle
    height="400"
    delimiter-icon="mdi-post"
    hide-delimiter-background
    show-arrows-on-hover
  >
    <v-carousel-item
      v-for="(blogpost, i) in blogposts"
      :key="i"
      :to="'/BlogPost/' + blogpost.ID"
    >
      <v-img
        contain
        height="400"
        :src="require('@/assets/images/' + blogpost.PICTURENAME)"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-row class="fill-height" align="end" justify="center">
          <div class="display-1 primary ml-4 mr-4 mb-10">
            {{ blogpost.TITLE }}
          </div>
        </v-row>
      </v-img>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  data: () => ({
    blogposts: [],
  }),

  components: {},

  mounted() {
    this.getBlogposts();
  },

  methods: {
    getBlogposts() {
      this.$store.dispatch("getRandomBlogposts").then((response) => {
        if (response.msg != null) {
          //TODO: Was tun wir wenn wir kein Element erhalten???
        } else {
          this.blogposts = response;
        }
      });
    },
  },
};
</script>

<style>
</style>